@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,600;1,900&display=swap');
@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;
}

body {
  background: #f3f3f3;
  font-family: 'Inter', sans-serif;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  align-items: center;
  position: fixed;
}

header p {
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 1.2s ease-out;
}

header p.hidden {
  opacity: 0;
}

section {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.section--container {
  margin-top: 5vh;
  margin-left: 5vw;
}

.section--container.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.section--container h1 {
  font-weight: 900;
  font-size: 13rem;
  letter-spacing: -6px;
  font-style: italic;
  width: 30%;
  line-height: 10rem;
  font-family: 'Nunito Sans', sans-serif;
}

.support--content {
  position: relative;
  top: -15%;
  left: 100px;
}

.support--content p {
  width: 450px;
  margin-bottom: 48px;
  line-height: 1.5rem;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

button {
  background-color: #dd5c18;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bolder;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: inset 0 0 0 0.09px black;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 30px;
  user-select: auto;
}

button:hover {
  box-shadow: inset 250px 0 0 0 black;
  transform: scale(1.1);
}

/* PAGES */

.pages {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.pages span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.pages span svg {
  width: 24px;
  padding-right: 5px;
}

.share {
  bottom: 40px;
  position: absolute;
  right: 40px;
}

.navBtns {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  z-index: 10;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #CCC;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.circle svg {
  color: #f8f8ff; /* Off white color */
}

.exit {
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: black;
  box-shadow: inset 0 0 0 0.09px black;
}

.exit:hover {
  box-shadow: inset -250px 0 0 0 black;
}

@media screen and (max-width: 600px) {
  .section--container {
    margin-left: 15vw;
  }

  .section--container h1 {
    font-size: 8rem;
    letter-spacing: -6px;
    line-height: 6rem;
  }

  .navBtns {
    margin-bottom: 20px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }

  .support--content {
    top: -10%;
    left: 0;
  }
}

/* FLIP BOOK */

.flipbook-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.magazine {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.magazine .page {
  height: 100%;
}

.magazine .page img {
  max-width: 100%;
  height: 100%;
}

.drop-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
